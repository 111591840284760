<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :data="charges"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :limit="limit"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @Changelimit="Changelimit"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
        currentUser: { type: Object},
        idWharhouse: { type: Object}
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      formData: {},
      charges: [],
      sellers: [],
      tabId:[],
      limit: 10,
      filters:{},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
    };
  },
  computed: {
    warhouseSelected(){
            return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  }, 
  watch: { 
      'warhouseSelected': async function(oldVal, newVal) {

      },
      'idWharhouse': async function(oldVal, newVal) {
          this.idWharhouse=oldVal;  
          await this.reset();
          if (this.currentUser.type != "Seller") {  await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
      }
},
  async mounted() {
    if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
    else this.$f.objectToQueryString({limit:this.limit,'warehouse._id':this.idWharhouse._id})

    if(this.filters.name) await this.search(this.filters.name);
    else await this.getAvoirs(this.filters);
    if (this.currentUser.type != "Seller") await this.getSellers();
    this.setDataSelect("seller", this.sellers, null);
    this.setDataSelect("status", ['not applied', 'applied'], null);
  },
  methods: {
    async getwharhouseId(){
       await this.$store.dispatch('wharhouse/getwharhouseId',{country: this.warhouseSelected,}).then((res) => {this.idWharhouse=res;})
    },
     setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getSellers() {
      const filter = {
         type: "Seller",
         countries:this.idWharhouse.country,
         limit:10,
      };
       if (this.currentUser.type != "Seller") {
          const res = await this.$server.search("users", filter);
          if (res.content.results) this.sellers = res.content.results;
          else this.sellers = [];
       }
    },
    async Changelimit(val){
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.filters['limit']=val;
       this.filters['page']=1;
       this.paginate.currentpage=1;
       this.$f.objectToQueryString(this.filters)
       if(this.filters.name) await this.search(this.filters.name);
       else this.getAvoirs(this.filters);
    },
    async filtrer(data) {
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       delete  this.filters.page;
       delete  this.filters.name;

       console.log('filter search________',data)
       this.paginate.currentpage=1;
       if(!this.filters['limit']) this.filters['limit']=this.limit;
       if(data.seller)  {this.filters.seller=data.seller._id;}else delete this.filters.seller;
       if(data.status)  {this.filters.status=data.status;}else delete this.filters.status;
       if(data.Range_date && data.Range_date[0]){this.filters.Range_date=data.Range_date.map( el => this.$moment(el).format('yyyy-MM-DD'));}else delete this.filters.Range_date;
       this.$f.objectToQueryString(this.filters)
      await this.getAvoirs(this.filters);
    },
    /**********************************User and Status******************************************************/
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
      console.log(res.content);
    },
   
    async reset() {
        let lmitt=this.limit;
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        if(this.filters['limit']) lmitt=this.filters['limit'];
        this.filters['page']=1;
        this.$f.objectToQueryString({limit:lmitt,'warehouse._id':this.idWharhouse._id})
        await this.getAvoirs( {limit:lmitt});
    },
    async refresh() {
        if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
        this.$f.objectToQueryString(this.filters)
        if(this.filters.name) await this.search(this.filters.name);
        else await this.getAvoirs(this.filters);
       if(this.filters['page'] && this.filters['page']>1 && this.charges.length==0){
          this.filters['page']= --this.filters['page'];
           this.$f.objectToQueryString(this.filters)
          await this.getAvoirs(this.filters);
       }
    },
    async getAvoirs(filters) {
      this.loading = true;
      filters['warehouse._id']=this.idWharhouse._id;
      const res = await this.$server.search("charges", filters);
      this.loading = false;
      if (res && res.content.results) {
        this.charges = res.content.results;
        this.charges.forEach(function (avoir) {
          if(avoir.payment && avoir.payment._id) {
            avoir.payment = '<a class="text-blue-600" target="_blank" href="/payments/detail/'+avoir.payment._id+'"><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"/></svg></a>'
            avoir.status ='applied';
          }
        }.bind(this));
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
    },
    async search(data) {
       this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
       let limitt=this.limit
       if(this.filters['limit']) limitt=this.filters['limit'];
       const search = {
        name:data.trim(),
        limit:parseInt(limitt),
        'warehouse._id':this.idWharhouse._id
      }

     this.$f.objectToQueryString(search)
     const  res = await this.$server.find("charges", search);
       if (res.content.results) {
        this.charges = res.content.results; 
        this.charges.forEach(function (avoir) {if(avoir.payment && avoir.payment._id) { avoir.status ='applied';avoir.payment = '<a class="text-blue-600" target="_blank" href="/payments/detail/'+avoir.payment._id+'"><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"/></svg></a>'}}.bind(this));
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.charges = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
        
    },
    async remove(id) {
      this.$confirm("You want to delete this avoir?").then(async (res) => {
        if (res) {
          const resp = await this.$server.delete("charges", { id: id });
          await this.refresh();
          alert("avoir Deleted", "success");
        }
      });
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.filters['page']=page;
      this.$f.objectToQueryString(this.filters)

      this.getAvoirs(this.filters);
    },
  },
};
</script>

<style>
/* animation for vue transition tag */

</style>
