export default {
    list: {
        title: "Charges",
        name: 'charges',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                name: "Actions",
                buttons: [{
                        text: "New charge",
                        icon: "add",
                        link: '/charges/new',
                        color: 'primary'
                    }
                ],
            }
        },
        columns: [
            {
                name: "Id",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "id",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Seller",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "seller",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-bold text-gray-700",
                                icone: "contacts"
                            },
                            // {
                            //     name: "email",
                            //     class: "font-light text-gray-700",
                            //     icone: "email"
                            // }
                        ]
                        }]

                    }, ],
                }, ]
            },
          
            {
                name: "Amount",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "amount",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Comment",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "comment",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Payment",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "payment",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Date creation",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Status",
                class: "text-center",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'not applied':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                case 'applied':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break;
                            }
                        },
                    }],
                }, ]
            },
            
        ],
        actions: {
            name: "Actions",
            width: "10px",
            class: "justify-end",
            elements: [
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/charges/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'charges',
            hasFilterdate:true,
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "id,Amount ...",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [
                    {
                        label: "Seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Status",
                        key: "status",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Charges date",
                        key: "Range_date",
                        value: null,
                        placeholder: "Date Range ...",
                        type: "date_range",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}
